import React, {useEffect, useState} from "react";
import Error from "./generic/Error";

function AddColumn({columns, addColumn}) {
    const [name, setName] = useState('');
    const [type, setType] = useState('text');
    const [error, setError] = useState(null);
    const [defaultValue, setDefaultValue] = useState("");
    const [defaultValuePlaceHolder, setDefaultValuePlaceHolder] = useState("");

    async function onSubmit(evt) {
        evt.preventDefault();

        setError(null);
        if (columns.map(column => column.name).includes(name)) {
            setError("This column already exists.");
        }
        else {
            try {
                await addColumn(name, type, defaultValue);
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        if (type === "text") {
            setDefaultValuePlaceHolder("Any string | uuid");
        }
        else if (type === "number") {
            setDefaultValuePlaceHolder("1234.01 | autoincrement");
        }
        else if (type === "boolean") {
            setDefaultValuePlaceHolder("true | false");
        }
        else if (type === "html") {
            setDefaultValuePlaceHolder("Any legal HTML");
        }
    }, [type]);

    return (
        <form onSubmit={onSubmit} className={"bg-light shadow-sm border p-2"}>
            {error && <Error message={error}/>}
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id={"name"} placeholder={"Column name"} required={true} className={"form-control"} value={name} onChange={(evt) => {
                    setName(evt.target.value);
                }}/>
            </div>
            <div className="form-group">
                <label htmlFor="type">Type:</label>
                <select id={"type"} className={"form-control"} value={type} onChange={(evt) => {
                    setType(evt.target.value);
                }}>
                    <option value="number">Number</option>
                    <option value="text">Text</option>
                    <option value="boolean">Boolean</option>
                    <option value="html">HTML</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="defaultValue">Default value:</label>
                <input type={"text"} id={"defaultValue"} placeholder={defaultValuePlaceHolder} className={"form-control"} value={defaultValue} onChange={(evt) => {
                    const defaultValue = evt.target.value;

                    if (type === "number" && (!isNaN(defaultValue) || defaultValue === "" || "autoincrement".startsWith(defaultValue))) {
                        setDefaultValue(defaultValue);
                    }
                    else if (type === "boolean" && (defaultValue === "true" || defaultValue === "false" || defaultValue === "" || "true".startsWith(defaultValue) || "false".startsWith(defaultValue))) {
                        setDefaultValue(defaultValue);
                    }
                    else if (type === "text" || type === "html"){
                        setDefaultValue(defaultValue);
                    }
                }} />
            </div>
            <button className={"btn btn-success"} type={"submit"}>Add column</button>
        </form>
    );
}

export default AddColumn;
