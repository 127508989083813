import React, {useEffect, useState} from "react";
import {updateRow} from "../db";
import {updateRow as updateRowServer} from "../models/files";

function RemoveFromExport({rowId, initialChecked, rows, columns, setRows, fileName, token, autoSync, changeToken, changeUser}) {
    const [checked, setChecked] = useState(initialChecked);

    useEffect(() => {
        setChecked(initialChecked);
    }, [initialChecked]);
    async function onChange(evt) {
        evt.persist();
        setChecked(evt.target.checked);

        const oldRow = rows[rowId];

        const row = {
            removeFromExport: evt.target.checked,
            row: {},
            id: oldRow.id
        };

        for (let key in oldRow.row) {
            row.row[key] = oldRow.row[key].value;
        }

        await updateRow(row, fileName);

        setRows(prevState => {
            const newState = [...prevState];
            newState[rowId].removeFromExport = evt.target.checked;
            return newState;
        });

        if (token && autoSync) {
            await updateRowServer(fileName, row, columns, token, changeToken, changeUser);
        }
    }

    return <input type="checkbox" checked={checked} onChange={onChange}/>
}

export default RemoveFromExport;
