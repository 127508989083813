import React, {Fragment, useState} from "react";
import Column from "./Column";
import AddColumn from "./AddColumn";
import {updateColumn} from "../models/files";

function ColumnSettings({columns, setColumns, addColumn, token, autoSync, changeToken, changeUser, fileName}) {
    const [totalWidth, setTotalWidth] = useState(Math.round(columns.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.width);
    }, 0)));

    async function changeWidth(index, width) {
        const newColumns = [...columns];
        newColumns[index].width = width;
        setColumns(newColumns);
        setTotalWidth(Math.round(newColumns.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.width);
        }, 0)));
    }

    async function changeShow(index, show) {
        const newColumns = [...columns];
        newColumns[index].show = show;

        if (show === false) {
            for (let i = 0; i < newColumns.length; i++) {
                if (i !== index && newColumns[i].show) {
                    newColumns[i].width = parseInt(newColumns[index].width) + parseInt(newColumns[i].width);
                    break;
                }
            }
            newColumns[index].width = 0;
        }
        else {
            newColumns[index].width = 100 - totalWidth;
        }

        if (token && autoSync) {
            await updateColumn(fileName,newColumns[index], token, changeToken, changeUser);
        }
        setColumns(newColumns);
    }

    async function changeType(index, type) {
        const newColumns = [...columns];
        newColumns[index].type = type;
        if (token && autoSync) {
            await updateColumn(fileName,newColumns[index], token, changeToken, changeUser);
        }
        setColumns(newColumns);
    }

    async function changeDefaultValue(index, defaultValue) {
        const newColumns = [...columns];
        newColumns[index].defaultValue = defaultValue;
        if (token && autoSync) {
            await updateColumn(fileName,newColumns[index], token, changeToken, changeUser);
        }
        setColumns(newColumns);
    }

    return (
        <Fragment>
            <div className={"text-right"}>
                <button className={"btn btn-primary"} data-toggle={"modal"} data-target={"#columnSettingsModal"}>Column settings</button>
            </div>

            <div className="modal" id={"columnSettingsModal"}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Column Settings</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <table className={"table-sm table-light w-100"}>
                                <caption>Columns</caption>
                                <thead className={"thead-dark"}>
                                <tr>
                                    <th>Name</th>
                                    <th>Width</th>
                                    <th>Type</th>
                                    <th>Default value</th>
                                    <th>Show</th>
                                </tr>
                                </thead>
                                <tbody>
                                {columns.map((column, index) => {
                                    return <Column key={index} index={index} totalWidth={totalWidth} changeWidth={changeWidth} changeDefaultValue={changeDefaultValue} changeShow={changeShow} changeType={changeType} column={column} />;
                                })}
                                </tbody>
                            </table>

                            <AddColumn columns={columns} addColumn={addColumn}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default ColumnSettings;
