import React, {useEffect, useState} from "react";
import Error from "./generic/Error";
import Success from "./generic/Success";
import {register} from "../models/users";
import Spinner from "./generic/Spinner";
import {useHistory} from "react-router-dom";

function Register ({changeToken, changeUser, token, setErrors, setSuccesses}) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setErrors([]);
        setSuccesses([]);
    }, []);

    async function onSubmit(evt) {
        evt.preventDefault();
        setLoading(true);
        setErrors([]);
        setSuccesses([]);
        if (password !== confirmPassword) {
            setErrors((errors) => {
                return [...errors, 'Please make sure your passsword is the same as your confirmed password.'];
            });
            setLoading(false);
        }
        else {
            try {
                const response = await register(firstName, lastName, email, password);
                if (response.errors.length < 1) {
                    changeToken(response.token);
                    changeUser(response.user);
                    setSuccesses((successes) => {
                        return [...successes, 'Successfully registered.'];
                    });
                    setLoading(false);
                    history.push('/addFile');
                }
                else {
                    setErrors((errors) => {
                        return [...errors, ...response.errors];
                    });
                    setLoading(false);
                }
            }
            catch (e) {
                console.error(e);
                setErrors((errors) => {
                    return [...errors, 'Could not reach the server. Do you have an internet connection?']
                });
                setLoading(false);
            }
        }
    }

    return (
        <div className={"bg-white mt-2 w-50 mx-auto p-3 border rounded"}>
            <form onSubmit={onSubmit} >
                <div className="form-row">
                    <div className="form-group col">
                        <label htmlFor="firstName">First name:</label>
                        <input type="text" id="firstName" className={"form-control"} required={true} disabled={loading || token} placeholder={"First name"} value={firstName} onChange={(evt) => {
                            setFirstName(evt.target.value);
                        }}/>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="lastName">Last name:</label>
                        <input type="text" id="lastName" className={"form-control"} required={true} disabled={loading || token} placeholder={"Last name"} value={lastName} onChange={(evt) => {
                            setLastName(evt.target.value);
                        }}/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email address:</label>
                    <input type="email" id={"email"} className={"form-control"} required={true} disabled={loading || token} placeholder={"Email address"} value={email} onChange={(evt) => {
                        setEmail(evt.target.value);
                    }}/>
                </div>
                <div className="form-row">
                    <div className="form-group col">
                        <label htmlFor="password">Password:</label>
                        <input type="password" id={"password"} className={"form-control"} disabled={loading || token} required={true} placeholder={"Password"} value={password} onChange={(evt) => {
                            setPassword(evt.target.value);
                        }}/>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="confirmPassword">Confirm password:</label>
                        <input type="password" id={"confirmPassword"} className={"form-control"} disabled={loading || token} required={true} placeholder={"Confirm password"} value={confirmPassword} onChange={(evt) => {
                            setConfirmPassword(evt.target.value);
                        }}/>
                    </div>
                </div>

                {loading ? <Spinner />:  <button type={"submit"} disabled={loading || token} className={"btn btn-success"}>Register</button>}
            </form>
        </div>
    );
}

export default Register;
