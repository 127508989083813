import React, {Fragment, useEffect, useState} from "react";
import {getCount, getCountSearch, getRows, getRowsSearch} from "../db";
import Papa from "papaparse";
import ArrowDown from "./generic/ArrowDown";
import ArrowUp from "./generic/ArrowUp";

function  ExportFile({fileName, columns: columnsFromParent, searches, regexpSearches}) {
    const [type, setType] = useState('CSV');
    const [delimiter, setDelimiter] = useState(',');
    const [columns, setColumns] = useState([]);
    const [exportSearchResults, setExportSearchResults] = useState(false);
    const [fileNameForExport, setFileNameForExport] = useState("");

    useEffect(() => {
        setFileNameForExport(fileName);
    }, [fileName]);

    useEffect(() => {
        setColumns(columnsFromParent.map((column, index) => {
            return {
                name: column.name,
                export: true,
                nameToUse: column.name,
                id: index,
                languageCode: ''
            }
        }));
    }, [columnsFromParent.length]);

    async function exportListener(evt) {
        evt.preventDefault();
        window.$('#exportFileModal').modal('hide');
        window.$('#exportFileModal').on('hidden.bs.modal', async (e) => {
            window.$('#exportFileModal').off('hidden.bs.modal');

            let rows = [];

            if ((searches.length > 0 || regexpSearches.length > 0) && exportSearchResults) {
                const count = await getCountSearch(fileName, searches, regexpSearches);
                rows = (await getRowsSearch(fileName, 0, count, searches, regexpSearches));
            }
            else {
                const count = await getCount(fileName);
                rows = (await getRows(fileName, 0, count));
            }

            rows = rows.filter(row => !row.removeFromExport).map(row => {
                let rowForMapping = [];
                for (let i = 0; i < columns.length; i++) {
                    if (columns[i].export) {
                        rowForMapping.push(row.row[columns[i].name.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')]);
                    }
                }
                return rowForMapping;
            });

            let fileNameToUse = fileNameForExport;

            if (type === "CSV") {
                let config = {
                    delimiter: delimiter,
                    header: true
                };

                if (delimiter === "tab") {
                    config.delimiter = "\t";
                    config.quoteChar = "";
                    config.escapeChar = "";
                    rows = rows.map(value => {
                        return value.map(value => {
                            return value.replace(/[\n\r\t]|\s{2,}/g, ' ');
                        });
                    });
                    fileNameToUse += ".tsv";
                }
                else {
                    fileNameToUse += ".csv";
                }

                const text = Papa.unparse({
                    fields: columns.filter(column => column.export).map(column => column.nameToUse.trim()),
                    data: rows
                }, {
                    ...config,
                });

                const file = new File([text], fileNameToUse, {
                    type: "text/tab-separated-values"
                });
                const a = document.createElement("a");
                a.href = URL.createObjectURL(file);
                a.download = fileNameToUse;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
            else if (type === "TMX") {
                const languageCodes = columns.filter(value => value.export).map(value => value.languageCode);

                let text = "<?xml version=\"1.0\" encoding=\"utf-8\" ?>\n" +
                    "<tmx version=\"1.4\">\n" +
                    " <header\n" +
                    "  creationtool=\"TSV-editor\"\n" +
                    "  creationtoolversion=\"1.0\"\n" +
                    "  datatype=\"PlainText\"\n" +
                    "  segtype=\"sentence\"\n" +
                    "  adminlang=\"en-us\"\n" +
                    "  srclang=\"" + languageCodes[0] +"\"\n" +
                    "  o-tmf=\"Export-TSV-editor\"\n" +
                    " ></header>" +
                    "  <body>";

                for (let i = 0; i < rows.length; i++) {
                    text += "<tu>\n";
                    for (let j = 0; j < rows[i].length; j++) {
                        text += "<tuv xml:lang=\"" + languageCodes[j] +"\"><seg>" +  rows[i][j].replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&apos;').replace(/"/g, '&quot;') + "</seg></tuv>\n";
                    }
                    text += "</tu>\n";
                }

                text += "</body>\n";
                text += "</tmx>";

                fileNameToUse += ".tmx";

                const file = new File([text], fileNameToUse, {
                    type: "application/x-tmx"
                });
                const a = document.createElement("a");
                a.href = URL.createObjectURL(file);
                a.download = fileNameToUse;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        });
    }

    function changeValue(id, key, value) {
        const newColumns = [...columns];
        for (let i = 0; i < newColumns.length; i++) {
            if (newColumns[i].id === id) {
                newColumns[i][key] = value;
                break;
            }
        }
        setColumns(newColumns);
    }

    return (
        <Fragment>
            <button type={"button"} className={"btn btn-success"} data-target={"#exportFileModal"} data-toggle={"modal"}>Export file</button>

            <div className="modal fade" id={"exportFileModal"} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={exportListener}>
                            <div className="modal-header">
                                <h5 className="modal-title">Export file</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="fileName">File name:</label>
                                <input type="text" required={true} className={"form-control"} id={"fileName"} value={fileNameForExport} onChange={(evt) => {
                                    setFileNameForExport(evt.target.value.trimStart());
                                }}/>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="importType">File type:</label>
                                    <select id="importType" className={"form-control"} required={true} value={type} onChange={(evt) => {
                                        setType(evt.target.value)
                                    }}>
                                        <option value="CSV">CSV</option>
                                        <option value="TMX">TMX</option>
                                    </select>
                                </div>
                                {type !== "TMX" && <div className="form-group col">
                                    <label htmlFor="importDelimiter">Delimiter:</label>
                                    <select id={"importDelimiter"} value={delimiter} required={true} className={"form-control"} onChange={(evt) => {
                                        setDelimiter(evt.target.value);
                                    }}>
                                        <option value=",">Comma</option>
                                        <option value=";">Semicolon</option>
                                        <option value="tab">Tab</option>
                                    </select>
                                </div>}
                            </div>
                            <div className="table-responsive">
                                <table className={"table table-sm table-light table-striped"}>
                                    <caption>Columns</caption>
                                    <thead className={"thead-dark"}>
                                        <tr>
                                            <th>Column</th>
                                            <th>Name to use in export</th>
                                            <th><input type="checkbox" onChange={(evt) => {
                                                const newColumns = [...columns];
                                                newColumns.map((column) => {
                                                    column.export = evt.target.checked;
                                                    return column;
                                                });
                                                setColumns(newColumns);
                                            }}/></th>
                                            {type === "TMX" && <th>Language code</th>}
                                            <th>Change order</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {columns.map((column, index) => {
                                        return (
                                        <tr draggable={"true"} key={column.id} onDragOver={(evt) => {
                                            evt.preventDefault();
                                            if (evt.target.parentElement.tagName === "TR") {
                                                evt.target.parentElement.classList.add("table-primary");
                                            }
                                            else if (evt.target.parentElement.parentElement.tagName === "TR") {
                                                evt.target.parentElement.parentElement.classList.add("table-primary");
                                            }
                                        }} onDrop={(evt) => {
                                            evt.preventDefault();
                                            if (evt.target.parentElement.tagName === "TR") {
                                                evt.target.parentElement.classList.remove("table-primary");
                                            }
                                            else if (evt.target.parentElement.parentElement.tagName === "TR") {
                                                evt.target.parentElement.parentElement.classList.remove("table-primary");
                                            }

                                            try {
                                                const data = JSON.parse(evt.dataTransfer.getData('application/json'));
                                                setColumns((prevColumns) => {
                                                    const newColumns = [...prevColumns];
                                                    const [element] = newColumns.splice(data.index, 1);
                                                    newColumns.splice(index, 0, element);
                                                    return newColumns;
                                                });
                                            }
                                            catch (e) {
                                                console.error(e);
                                            }

                                        }} onDragStart={(evt) => {
                                            evt.dataTransfer.setData('application/json', JSON.stringify({
                                                id: column.id,
                                                index
                                            }));
                                            evt.dataTransfer.dropEffect = "move";
                                        }} onDragLeave={(evt) => {
                                            if (evt.target.parentElement.tagName === "TR") {
                                                evt.target.parentElement.classList.remove("table-primary");
                                            }
                                            else if (evt.target.parentElement.parentElement.tagName === "TR") {
                                                evt.target.parentElement.parentElement.classList.remove("table-primary");
                                            }
                                        }}>
                                            <td>{column.name}</td>
                                            <td><input type="text" className={"form-control"} required={column.export} value={column.nameToUse} onChange={(evt) => {
                                                changeValue(column.id, "nameToUse", evt.target.value.trimStart().replace(/\s{2,}/g, ' '));
                                            }}/></td>
                                            <td><input type="checkbox" checked={column.export} onChange={(evt) => {
                                                changeValue(column.id, "export", evt.target.checked);
                                            }}/></td>
                                            {type === "TMX" && <td><input type="text" className={"form-control"} required={column.export} value={column.languageCode} onChange={(evt) => {
                                                changeValue(column.id, "languageCode", evt.target.value.trim().replace(/\s{2,}/g, ' '));
                                            }}/></td>}
                                            <td><button className={"btn btn-sm"} type={"button"} disabled={index === 0} onClick={(evt) => {
                                                setColumns((prevColumns) => {
                                                    const newColumns = [...prevColumns];
                                                    newColumns.splice(index, 1);
                                                    newColumns.splice(index - 1, 0, prevColumns[index]);
                                                    return newColumns;
                                                });
                                            }}><ArrowUp /></button><button className={"btn btn-sm"} type={"button"} disabled={index === columns.length - 1} onClick={(evt) => {
                                                setColumns((prevColumns) => {
                                                    const newColumns = [...prevColumns];
                                                    newColumns.splice(index, 1);
                                                    newColumns.splice(index + 1, 0, prevColumns[index]);
                                                    return newColumns;
                                                });
                                            }}><ArrowDown /></button></td>
                                        </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exportSearchResults">Export search results: <input type="checkbox" id={"exportSearchResults"} checked={exportSearchResults} onChange={(evt) =>{
                                    setExportSearchResults(evt.target.checked);
                                }}/></label>
                            </div>
                        </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-success" >Export file</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ExportFile;
