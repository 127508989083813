import React, {useState} from "react";
import {getColumns as getColumnsFromServer,  getCount, getRows as getRowsFromServer} from "../../models/files";
import {addRows, clearRows,  initDb, removeDb} from "../../db";
import CloudDownload from "../generic/CloudDownload";

function Download({token, fileName, setLoading: setLoadingParent, changeToken, changeUser, changeColumns, changeVersion, changeRows, setVersion, processing, setProcessing, setErrors}) {
    const [currentAmount, setCurrentAmount] = useState(0);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    async function download() {
        setErrors([]);

        if (token) {
            setLoadingParent(true);
            setLoading(true);
            setProcessing(true);

            try {
                const countResponse = await getCount(fileName, token, changeToken, changeUser);


                if (countResponse.errors.length > 0) {
                    setCurrentAmount(0);
                    setLoading(false);
                    setLoadingParent(false);
                    setProcessing(false);
                    setErrors(prevErrors => {
                        return [...prevErrors, ...countResponse.errors];
                    });
                    return;
                }
                const {count} = countResponse;
                setCount(count);
                const quantity = 50;

                const maxPage = Math.floor((count -1)/ quantity);
                await clearRows(fileName);

                const columnsResponse = await getColumnsFromServer(fileName, token, changeToken, changeUser);
                if (columnsResponse.errors.length > 0) {
                    setLoading(false);
                    setLoadingParent(false);
                    setErrors(["Failed to import your online files."]);
                    return;
                }
                const {columns} = columnsResponse;
                await removeDb(fileName);
                await initDb(fileName, columns.map(column => column.name));

                setVersion(1);
                changeVersion(fileName, 1);

                for (let i = 0; i <= maxPage; i++) {
                    const rowsResponse = await getRowsFromServer(fileName, i, 50, token, changeToken, changeUser);

                    if (rowsResponse.errors.length > 0) {
                        setCurrentAmount(0);
                        setLoading(false);
                        setLoadingParent(false);
                        setProcessing(false);
                        setErrors(prevErrors => {
                            return [...prevErrors, ...rowsResponse.errors];
                        });
                        break;
                    }
                    const {rows} = rowsResponse;
                    await addRows(rows, fileName);
                    setCurrentAmount(currentAmount => {
                        return currentAmount + rows.length;
                    });
                }

                await changeRows({});
                changeColumns(columns.map((value, index) => {
                    return {...value, realIndex: index}
                }));
            }
            catch (e) {
                console.error(e);
            }
            setCurrentAmount(0);
            setProcessing(false);
            setLoading(false);
            setLoadingParent(false);
        }
    }

    let style = {
        cursor: "pointer",
        fontSize: "30px"
    };

    if (!token) {
        style.cursor = "";
    }

    return loading ? <p>{currentAmount}/{count} downloaded...</p>:
        (!processing ? <button disabled={!token} title={"The current version of your copy in the cloud, will be downloaded."} onClick={download} className={"btn btn-warning ml-1"}><CloudDownload /> Retrieve</button>: "");
}

export default Download;
