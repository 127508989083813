import React from "react";
import {getCount} from "../../models/files";
import CloudUpload from "../generic/CloudUpload";

function AutoSync({token, autoSync, setAutoSync, fileName, changeToken, changeUser, setErrors, processing}) {
    async function onChange(evt) {
        const checked = evt.target.checked;
        if (checked) {
            setErrors([]);

            const countResponse = await getCount(fileName, token, changeToken, changeUser);

            if (countResponse.errors.length > 0) {
                return setErrors(prevErrors => {
                    return [...prevErrors, "You cannot auto sync if the file is not on the server yet."];
                });
            }
        }

        setAutoSync(checked);
    }

    return (!processing ?  <div className="custom-control custom-switch d-inline-block m-2" title={"Once you opt for this, any change you make locally, will also be made to your copy in the cloud. "}>
        <input  type="checkbox" className="custom-control-input" id="autoSync" disabled={!token} checked={autoSync} onChange={onChange}/>
        <label className="custom-control-label" htmlFor="autoSync">Auto <CloudUpload/> Sync</label>
    </div> : "");
}

export default AutoSync;
