import React, {useRef, useState, Fragment} from "react";
import {addRows, clearRows, initDb, removeDb} from "../db";
import {getCount, getFiles, getRows as getRowsFromServer, getColumns as getColumnsFromServer} from "../models/files";
import Info from "./generic/Info";
import Papa from "papaparse";

import Spinner from "./generic/Spinner";
import CreateFile from "./CreateFile";

function AddFile({addFile, files, token, changeToken, changeUser, setErrors, setSuccesses}) {
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('CSV');
    const [delimiter, setDelimiter] = useState(',');
    const [currentAmount, setCurrentAmount] = useState(0);
    const [count, setCount] = useState(0);
    const [fileName, setFileName] = useState("");
    const [text, setText] = useState('');

    const file = useRef();

    async function onFileUpload(evt) {
        evt.preventDefault();

        setErrors([]);
        setSuccesses([]);
        setLoading(true);

        for (let i = 0; i < file.current.files.length; i++) {
            const fileName = file.current.files[i].name;
            setText('Importing ' + fileName);

            if (files.map(file => file.fileName).includes(fileName)) {
                setErrors((prevState) => {
                    return [...prevState, "You already imported a file with name: '" + fileName + "'."];
                });
            }
            else {
                if (type === "CSV") {
                    let config = {
                        delimiter: delimiter,
                        newline: "",
                        header: true,
                        skipEmptyLines: true
                    };

                    if (delimiter === "tab") {
                        config.delimiter = "\t";
                        config.quoteChar = "";
                    }

                    await new Promise((resolve) => {
                        Papa.parse(file.current.files[i], {
                            ...config,
                            error: (err) => {
                                console.error(err);
                                setErrors((prevState) => {
                                    return [...prevState, "Failed to import this CSV (" + fileName + "). Check your structure."]
                                });
                                resolve();
                            },
                            complete: async (results, parser) => {
                                if (results.errors.length > 0) {
                                    console.log(results.errors);
                                    setErrors((prevState) => {
                                        return [...prevState, "Failed to import this CSV (" + fileName + "). Check rows: " + results.errors.map((error) => error.row).toString() + "."]
                                    });
                                }
                                else {
                                    const header = results.meta.fields;
                                    const rowsToSet = results.data.map((fileRow, index) => {
                                        let newFileRow = {};

                                        for (let key in fileRow) {
                                            newFileRow[key.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = fileRow[key];
                                        }

                                        return {
                                            id: index,
                                            row: newFileRow,
                                            removeFromExport: false
                                        };
                                    });

                                    if (header.length === 0 || rowsToSet.length === 0) {
                                        setErrors((prevState) => {
                                            return [...prevState, "This file is empty."]
                                        });
                                    }
                                    else {
                                        try {
                                            await initDb(fileName, header);
                                            await clearRows(fileName);
                                            await addRows(rowsToSet, fileName);
                                            addFile(fileName);
                                            setSuccesses((prevState) => {
                                                return [...prevState, "Successfully added " + fileName + "."]
                                            });
                                        }
                                        catch (e) {
                                            console.error(e);
                                            setErrors((prevState) => {
                                                return [...prevState, "Failed to import this CSV (" + fileName + "). Check your structure."]
                                            });
                                            try {
                                                await removeDb(fileName);
                                            }
                                            catch (e) {
                                                console.error(e);
                                            }
                                        }
                                    }
                                }
                                resolve();
                            }
                        });
                    });
                }
                else if (type === "TMX") {
                    try {
                        const domParser = new DOMParser();
                        const doc = domParser.parseFromString(await readFile(file.current.files[0]), 'application/xml');
                        let properties = [];
                        let translationUnitVariants = [];

                        const tu = doc.getElementsByTagName("tu");
                        if (tu.length > 1) {
                            const props = tu[1].getElementsByTagName("prop");
                            for (let i = 0; i < props.length; i++) {
                                properties.push(props[i].getAttribute("type"));
                            }

                            const tuvs = tu[1].getElementsByTagName("tuv");
                            for (let i = 0; i < tuvs.length; i++) {
                                translationUnitVariants.push(tuvs[i].getAttribute("xml:lang"));
                            }
                        }
                        else {
                            const el = tu[1].getElementsByTagName("prop");
                            for (let i = 0; i < el.length; i++) {
                                properties.push(el[i].getAttribute("type"));
                            }

                            const tuvs = tu[1].getElementsByTagName("tuv");
                            for (let i = 0; i < tuvs.length; i++) {
                                translationUnitVariants.push(tuvs[i].getAttribute("xml:lang"));
                            }
                        }

                        let rows = [];
                        let header = [...properties, ...translationUnitVariants];

                        for (let i = 0; i < tu.length; i++) {
                            let row = {

                            };

                            const props = tu[i].getElementsByTagName("prop");
                            for (let i = 0; i < properties.length; i++) {
                                if (props[i]) {
                                    row[properties[i].replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = props[i].textContent;
                                }
                                else {
                                    row[properties[i].replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = "";
                                }
                            }

                            const tuvs = tu[i].getElementsByTagName("tuv");
                            for (let i = 0; i < translationUnitVariants.length; i++) {
                                if (tuvs[i]) {
                                    if (tuvs[i].getElementsByTagName("seg")[0]) {
                                        row[translationUnitVariants[i].replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = tuvs[i].getElementsByTagName("seg")[0].textContent;
                                    }
                                    else {
                                        row[translationUnitVariants[i].replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = "";
                                    }
                                }
                                else {
                                    row[translationUnitVariants[i].replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = "";
                                }
                            }
                            rows.push({
                                id: i,
                                row,
                                removeFromExport: false
                            });
                        }

                        try {
                            console.log(fileName, header);
                            await initDb(fileName, header);
                            await clearRows(fileName);
                            await addRows(rows, fileName);
                            addFile(fileName);
                            setSuccesses((prevState) => {
                                return [...prevState, "Successfully added '" + fileName + "'."]
                            });
                        }
                        catch (e) {
                            console.error(e);
                            setErrors((prevState) => {
                                return [...prevState, "Failed to import this TMX (" + fileName + ".) Check your structure."]
                            });
                            try {
                                await removeDb(fileName);
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }
                    }
                    catch (e) {
                        console.error(e);
                        setErrors((prevState) => {
                            return [...prevState, "Failed to parse this TMX file (" + fileName + ")."]
                        });
                    }
                }
                else {
                    setErrors([type + " is not supported yet."]);
                    setLoading(false);
                    break;
                }
            }
        }
        setLoading(false);
    }

    function readFile(blob) {
        return new Promise((resolve) => {
            const fileReader = new FileReader();

            fileReader.addEventListener("load", onLoad);

            function onLoad() {
                fileReader.removeEventListener("load", onLoad);
                resolve(fileReader.result);
            }

            fileReader.readAsText(blob);
        });
    }
    async function importFromAccount() {
        setErrors([]);
        setSuccesses([]);
        setLoading(true);

        try {
            const {files: filesFromServer} = await getFiles(token, changeToken, changeUser);
            if (filesFromServer.length < 1) {
                setErrors(["You have no files in your account."]);
            }
            else {
                for (let i = 0; i < filesFromServer.length; i++) {
                    const fileName = filesFromServer[i].fileName;
                    setFileName(fileName);
                    setCount(0);
                    setCurrentAmount(0);
                    if (files.map(file => file.fileName).includes(fileName)) {
                        setErrors(["You already imported " + fileName + "."]);
                    }
                    else {
                        const countResponse = await getCount(fileName, token, changeToken, changeUser);

                        if (countResponse.errors.length > 0) {
                            setLoading(false);
                            setErrors(["Failed to import your online files."]);
                            return;
                        }
                        const {count} = countResponse;
                        setCount(count);

                        if (count !== 0) {
                            const quantity = 50;
                            const maxPage = Math.floor((count -1)/ quantity);

                            for (let i = 0; i <= maxPage; i++) {
                                const rowsResponse = await getRowsFromServer(fileName, i, 50, token, changeToken, changeUser);

                                if (rowsResponse.errors.length > 0) {
                                    setLoading(false);
                                    setErrors(["Failed to import your online files."]);
                                    break;
                                }
                                const {rows} = rowsResponse;

                                if (i === 0) {
                                    const columnsResponse = await getColumnsFromServer(fileName, token, changeToken, changeUser);
                                    if (columnsResponse.errors.length > 0) {
                                        setLoading(false);
                                        setErrors(["Failed to import your online files."]);
                                        break;
                                    }
                                    const {columns} = columnsResponse;

                                    await initDb(fileName, columns.map(column => column.name));
                                    await clearRows(fileName);
                                    localStorage.setItem(fileName + "columns", JSON.stringify(columns));
                                }
                                await addRows(rows, fileName);
                                setCurrentAmount(currentAmount => {
                                    return currentAmount + rows.length;
                                });
                            }
                            addFile(fileName);
                            setSuccesses(["Successfully added " + fileName + "."]);
                        }
                    }
                }
            }
        }
        catch (e) {
            console.error(e);
            setErrors(["Failed to import your files from your account."]);
        }
        setLoading(false);
    }

    return (
        <div className={"bg-white mt-2 w-50 mx-auto p-3 border rounded"}>
            {loading &&
                <Fragment>
                    <Spinner />
                    {text && <p>{text}</p>}
                    {count > 0 && <p>{currentAmount}/{count} from {fileName} downloaded...</p>}
                </Fragment>}
                <div className={loading ? "d-none": ""}>
                    <form onSubmit={onFileUpload} >
                        <Info message={"Your data is stored on your local machine, not on any server (until you upload it)."} />
                        <div className="form-row">
                            <div className="form-group col">
                                <label htmlFor="importType">File type:</label>
                                <select id="importType" className={"form-control"} value={type} onChange={(evt) => {
                                    setType(evt.target.value)
                                }}>
                                    <option value="CSV">CSV</option>
                                    <option value="TMX">TMX</option>
                                </select>
                            </div>
                            {type !== "TMX" && <div className="form-group col">
                                <label htmlFor="importDelimiter">Delimiter:</label>
                                <select id={"importDelimiter"} value={delimiter} className={"form-control"} onChange={(evt) => {
                                    setDelimiter(evt.target.value);
                                }}>
                                    <option value=",">Comma</option>
                                    <option value=";">Semicolon</option>
                                    <option value="tab">Tab</option>
                                </select>
                            </div>
                            }
                        </div>


                        <div className="form-group">
                            <label htmlFor="file">Files to upload (must have headers):</label>
                            <input type="file" multiple={true} ref={file} className={"d-block"} style={{border: "1px solid black"}} required={true}/>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button type={"submit"} className={"btn btn-success"}>Import file</button>
                            {token && <button type={"button"} className={"btn btn-primary"} onClick={importFromAccount}>Import from account</button>}
                        </div>
                    </form>

                    <div className={"d-flex justify-content-between align-items-center my-3"}>
                        <div className={"border-bottom w-100"} style={{height:'1px'}}></div>
                        <span className={"mx-2"}>OR</span>
                        <div className={"border-bottom w-100"} style={{height:'1px'}}></div>
                    </div>


                    <CreateFile addFile={addFile} files={files}/>
                </div>
        </div>
    );
}

export default AddFile;
