import React, {useEffect, useState} from "react";

function SearchHeader({column, changeRegexpSearch, changeSearch, search: {search: initialSearch}, regexpSearch: {search: initialRegexpSearch}}) {
    const [search, setSearch] = useState(initialSearch);
    const [regexpSearch, setRegexpSearch] = useState(initialRegexpSearch);

    useEffect(() => {
        setSearch(initialSearch);
        setRegexpSearch(initialRegexpSearch);
    }, [initialRegexpSearch, initialSearch]);

    function onSearchChange(evt) {
        setSearch(evt.target.value);
        changeSearch(column.name, evt.target.value);
    }
    function onRegexpSearchChange(evt) {
        setRegexpSearch(evt.target.value);
        changeRegexpSearch(column.name, evt.target.value);
    }

    return (
        <td><input type="text" className={"form-control"} placeholder={"Search in " + column.name} dir={"auto"} value={search} onChange={onSearchChange}/>
            <input type="text" className={"form-control"} placeholder={"Regexp search"} value={regexpSearch} onChange={onRegexpSearchChange}/>
        </td>
    );
}

export default SearchHeader;
