import React, {Fragment} from "react";

function RemoveFile({removeFile, fileName, fileId}) {

    async function removeFileListener() {
        try {
            await removeFile(fileName);
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <Fragment>
            <div className="modal fade" id={"removeModal" + fileId} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Removal confirmation</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to remove {fileName}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                            <button type="button" className="btn btn-danger" data-dismiss={"modal"} onClick={removeFileListener}>Remove file</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default RemoveFile;
