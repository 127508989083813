import React, {useEffect, useState} from "react";

function Column ({column, changeWidth, changeType, changeDefaultValue, changeShow, index, totalWidth}) {
    const [width, setWidth] = useState(column.width);
    const [show, setShow] = useState(column.show);
    const [type, setType] = useState(column.type);
    const [test, setTest] = useState(column.defaultValue);
    const [defaultValuePlaceHolder, setDefaultValuePlaceHolder] = useState("");

    function changeWidthListener(width) {
        changeWidth(index, width);
    }
    function changeShowListener(show) {
        changeShow(index, show);
    }
    function changeTypeListener(type) {
        changeType(index, type);
    }
    function changeDefaultValueListener(evt) {
        const defaultValue = evt.target.value;

        if (type === "number" && (!isNaN(defaultValue) || defaultValue === "" || "autoincrement".startsWith(defaultValue))) {
            changeDefaultValue(index, defaultValue);
        }
        else if (type === "boolean" && (defaultValue === "true" || defaultValue === "false" || defaultValue === "" || "true".startsWith(defaultValue) || "false".startsWith(defaultValue))) {
            changeDefaultValue(index, defaultValue);
        }
        else if (type === "text" || type === "html"){
            changeDefaultValue(index, defaultValue);
        }
    }

    useEffect(() => {
        setWidth(column.width);
        setShow(column.show);
        setType(column.type);
        setTest(column.defaultValue);
    }, [column.width, column.show, column.type, column.defaultValue]);

    useEffect(() => {
        if (type === "text") {
            setDefaultValuePlaceHolder("Any string | uuid");
        }
        else if (type === "number") {
            setDefaultValuePlaceHolder("1234.01 | autoincrement");
        }
        else if (type === "boolean") {
            setDefaultValuePlaceHolder("true | false");
        }
        else if (type === "html") {
            setDefaultValuePlaceHolder("Any legal HTML");
        }
    }, [type]);

    return (
        <tr>
            <td>{column.name}</td>
            <td><input type="range" min={"0"} step={1} disabled={!show} value={width} onChange={(evt) => {
                if (totalWidth - width + parseInt(evt.target.value) >= 100) {
                    evt.preventDefault();
                }
                else {
                    changeWidthListener(evt.target.value);
                }
            }} className="form-control-range" id="formControlRange" /></td>
            <td><select className={"form-control"} value={type} onChange={(evt) => {
                changeTypeListener(evt.target.value);
            }}>
                <option value="number">Number</option>
                <option value="text">Text</option>
                <option value="boolean">Boolean</option>
                <option value="html">HTML</option>
            </select></td>
            <td><input type="text" placeholder={defaultValuePlaceHolder} className={"form-control"} value={test} onChange={changeDefaultValueListener}/></td>
            <td><input type="checkbox" checked={show} onChange={(evt) => {
                // setShow(evt.target.checked);
                changeShowListener(evt.target.checked);
            }}/></td>
        </tr>
    );
}

export default Column;
