const endPoint = process.env.REACT_APP_endPoint || 'https://api.tsvEditor.ldit.be';

export async function addRows (fileName, rows, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/addRows', {
        method: "POST",
        body: JSON.stringify({
            rows
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function addColumns (fileName, columns, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/addColumns', {
        method: "POST",
        body: JSON.stringify({
            columns
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }

    return response.json();
}

export async function clearRows (fileName, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/clearRows', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function clearColumns (fileName, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/clearColumns', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function getCount (fileName, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/count', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function getRows (fileName, page, quantity, token, setToken, setUser) {
    const url = new URL(endPoint + '/api/v1/files/' + fileName + '/rows');
    url.search = new URLSearchParams({
        page,
        quantity
    });

    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function getColumns (fileName, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/columns', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function removeFile(fileName, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/remove', {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function getFiles(token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function getFile(fileName, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function updateRow(fileName, row, columns, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/updateRow', {
        method: "PUT",
        body: JSON.stringify({
            row,
            columns
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}

export async function updateColumn(fileName, column, token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/files/' + fileName + '/updateColumn', {
        method: "PUT",
        body: JSON.stringify({
            column
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}
