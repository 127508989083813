import React from "react";
import {updateRow} from "../../db";
import {updateRow as updateRowServer} from "../../models/files";

import Text from "./Text";
import Number from "./Number";
import Boolean from "./Boolean";

function Input({initialValue, columnKey, setRows, selectedIndex, fileName, rows, setSelectedIndex, columns, token, changeToken, changeUser, autoSync}) {
    async function updateCell(value) {
        setRows(prevState => {
            const newState = [...prevState];

            newState[selectedIndex[0]].row[Object.keys(newState[0].row)[selectedIndex[1]]] = {
                value: value,
                text: value
            };
            return newState;
        });

        let row = rows[selectedIndex[0]];
        let newRow = {
            id: row.id,
            removeFromExport: row.removeFromExport,
            row: {}
        };

        let keys = Object.keys(row.row);

        for (let i = 0; i < keys.length; i++) {
            newRow.row[keys[i]] = row.row[keys[i]].value;
        }

        newRow.row[keys[selectedIndex[1]]] = value;

        await updateRow(newRow, fileName);
        if (token && autoSync) {
            await updateRowServer(fileName,newRow, columns, token, changeToken, changeUser);
        }
    }

    async function onBlur() {
        setSelectedIndex(null);
    }

    async function onKeyDown(evt) {
        switch (evt.key) {
            case "ArrowLeft":
                if (!(selectedIndex[1] <= 0)) {
                    evt.preventDefault();
                    const newSelectedIndex = [...selectedIndex];

                    const realIndex = [...columns].reverse().find((element) => {
                        return element.realIndex < selectedIndex[1] && element.show;
                    });

                    if (realIndex !== undefined) {
                        newSelectedIndex[1] = realIndex.realIndex;
                        setSelectedIndex(newSelectedIndex)
                    }
                }
                break;
            case "ArrowRight":
                const keys = Object.keys(rows[0].row);
                if (!(selectedIndex[1] >= keys.length - 1)){
                    evt.preventDefault();
                    const newSelectedIndex = [...selectedIndex];

                    const realIndex = [...columns].find((element) => {
                        return element.realIndex > selectedIndex[1] && element.show;
                    });

                    if (realIndex !== undefined) {
                        newSelectedIndex[1] = realIndex.realIndex;
                        setSelectedIndex(newSelectedIndex)
                    }
                }
                break;
            case "ArrowUp":
                if (!(selectedIndex[0] <= 0)){
                    evt.preventDefault();
                    const newSelectedIndex = [...selectedIndex];
                    newSelectedIndex[0] -= 1;
                    setSelectedIndex(newSelectedIndex)
                }
                break;
            case "ArrowDown":
            case "Enter":
                if (!(selectedIndex[0] >= rows.length - 1)){
                    evt.preventDefault();
                    const newSelectedIndex = [...selectedIndex];
                    newSelectedIndex[0] += 1;
                    setSelectedIndex(newSelectedIndex)
                }
                break;
            case "Tab":
                if (evt.shiftKey) {
                    if (!(selectedIndex[1] <= 0)) {
                        evt.preventDefault();
                        const newSelectedIndex = [...selectedIndex];

                        const realIndex = [...columns].reverse().find((element) => {
                            return element.realIndex < selectedIndex[1] && element.show;
                        });

                        if (realIndex !== undefined) {
                            newSelectedIndex[1] = realIndex.realIndex;
                            setSelectedIndex(newSelectedIndex)
                        }
                    }
                }
                else {
                    const keys = Object.keys(rows[0].row);
                    if (!(selectedIndex[1] >= keys.length - 1)){
                        evt.preventDefault();
                        const newSelectedIndex = [...selectedIndex];

                        const realIndex = [...columns].find((element) => {
                            return element.realIndex > selectedIndex[1] && element.show;
                        });

                        if (realIndex !== undefined) {
                            newSelectedIndex[1] = realIndex.realIndex;
                            setSelectedIndex(newSelectedIndex)
                        }
                    }
                }
                break;
            default:
                if (evt.key === "Backspace")
                if (columns[columnKey].type === "number") {
                    if (isNaN(evt.key) && evt.key !== "Backspace" && evt.key !== "Delete") {
                        evt.preventDefault();
                    }
                }
                break;
        }
    }

    switch (columns[columnKey].type) {
        case "number":
            return <Number onBlur={onBlur} onKeyDown={onKeyDown} updateCell={updateCell} initialValue={initialValue}/>;
        case "text":
        case "html":
            return <Text onBlur={onBlur} onKeyDown={onKeyDown} updateCell={updateCell} initialValue={initialValue}/>;
        case "boolean":
            return <Boolean onBlur={onBlur} onKeyDown={onKeyDown} updateCell={updateCell} initialValue={initialValue}/>;
        default:
            break;
    }

}

export default Input;
