import React, {useState} from "react";

import Upload from "./Upload";
import Download from "./Download";
import AutoSync from "./AutoSync";

function Synchronize({fileName, token, count, changeToken, columns, changeUser, setLoading, loading, changeRows, changeColumns, setErrors, autoSync, setAutoSync, setVersion, changeVersion}) {
    const [processing, setProcessing] = useState(false);

    return (
        <div className={"mt-1 d-flex justify-content-between"}>
            <Upload count={count} columns={columns} token={token} fileName={fileName} changeUser={changeUser} changeToken={changeToken}
                    processing={processing} setProcessing={setProcessing} setErrors={setErrors}/>
            <AutoSync token={token} autoSync={autoSync} setAutoSync={setAutoSync} setErrors={setErrors} changeUser={changeUser}
                      changeToken={changeToken} fileName={fileName} processing={processing}/>
            <Download changeToken={changeToken} changeUser={changeUser} changeColumns={changeColumns} fileName={fileName} token={token}
                      changeRows={changeRows} processing={processing} setProcessing={setProcessing} setLoading={setLoading} loading={loading} setErrors={setErrors} setVersion={setVersion} changeVersion={changeVersion}/>
        </div>
    )
}

export default Synchronize;
