import React from "react";

function Pagination ({page, maxPage, setPage}) {
    function goToPage(evt, pageToGoTo) {
        evt.preventDefault();
        setPage(pageToGoTo);
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center m-0">
                <li className={"page-item" + (page <= 0 ? " disabled" : "")}>
                    <a className="page-link"  tabIndex="-1" href={"#"} aria-disabled={page <= 0} onClick={(evt) => {
                        goToPage(evt, 0);
                    }}>&lt;&lt;</a>
                </li>
                <li className={"page-item" + (page <= 0 ? " disabled" : "")}>
                    <a className="page-link"  tabIndex="-1" href={"#"} aria-disabled={page <= 0} onClick={(evt) => {
                        goToPage(evt, page - 1);
                    }}>&lt;</a>
                </li>
                <li className="page-item disabled"><a className="page-link" href="#">{page + 1}</a></li>

                <li className={"page-item" + (page >= maxPage ? " disabled": "")}>
                    <a className="page-link" href="#" aria-disabled={page >= maxPage} onClick={(evt) => {
                        goToPage(evt, page + 1);
                    }}>&gt;</a>
                </li>
                <li className={"page-item" + (page >= maxPage ? " disabled": "")}>
                    <a className="page-link" href="#" aria-disabled={page >= maxPage} onClick={(evt) => {
                        goToPage(evt, maxPage);
                    }}>&gt;&gt;</a>
                </li>
            </ul>
        </nav>
    );
}

export default Pagination;
