import React from "react";

function Quantity ({setQuantity, quantity}) {
    return (
        <div className="form-group row mt-1 mb-0">
            <label htmlFor="quantity" className={"col-sm-4 col-form-label"}>Quantity:</label>
            <div className="col-sm-8">
                <select className={"form-control"} id={"quantity"} value={quantity} onChange={(evt) => {
                    setQuantity(evt.target.value);
                }}>
                    <option value="1">1</option>
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="80">80</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
    );
}

export default Quantity;
