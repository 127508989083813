import React, {Fragment} from "react";

function UserGuide() {
	return (
		<div className={"bg-white p-3 my-2 border rounded"}>
			<h1>User Guide</h1>
			<p>TSV Editor is a tool intended for viewing and editing tsv, csv and tmx files. You can import and edit an existing file or you can create a new file from scratch. You can also convert files from tmx to csv or simply change the delimiter in your csv, then export the new file.</p>
			<p>Note: the data is stored on your local machine unless you choose to upload the files to the cloud. In this case, you will need to log in to your account.</p>
			<h3>Open a file</h3>
			<ol>
				<li>Go to 'Add file' tab</li>
				<li>Select the type in the 'File type' dropdown.
				<ul>
					<li>For CSV you also need to select the delimiter in the 'Delimiter' dropdown. The delimiter selected must match the delimiter used in your file.</li>
					<li>For TMX the 'Delimiter' is not applicable.</li>
				</ul></li>
				<li>Click on 'Browse...' button to go to the location where your files are placed.You can upload one or multiple files at a time.
				<ul>
					<li>When you select a single file, the file name is displayed next to the 'Browse...' button.</li>
					<li>When you select multiple files, a file count is displayed next to the 'Browse...' button. <br /><u>Note</u>: If you choose to open multiple files, it is recommended that they all have the same format defined in the previous step, otherwise they cannot be processed correctly.</li>
				</ul></li>
				<li>Click on 'Import file' button to complete the upload.
				<ul>
					<li>A confirmation message is shown at the top of the page and the file is opened in a new tab.</li>
					<li>An error message is shown when the file cannot be parsed, in which case the file is not opened.</li>
				</ul></li>
			</ol>
			<h3>Create a new file</h3>
			<ol>
				<li>Go to 'Add file' tab</li>
				<li>Provide a file name in 'File name' text box. The file extension will be added automatically at export, you don't need to specify it here.</li>
				<li>Set the amount of rows you want for your file in 'Amount of rows' by typing the number manually or by using the arrows to increment the number of rows.</li>
				<li>Add columns one by one:
				<ul>
					<li>provide the column name in the 'Name' textbox</li>
					<li>select the column type in the 'Type' dropdown. You can choose between: text, number and Boolean.</li>
					<li>Optional: set a default value in 'Default value' text box.</li>
				</ul>
					Specific default values can be set based on the column type:
					<ul>
						<li>'Number': you can provide a static numerical value that will be the same on all rows or you can set the value to be incremented automatically by typing "autoincrement" in the 'Default value' text box.Leave empty if you want to fill in the values later on.</li>
						<li>'Text': you can provide a static text value that will be the same on all rows or you can generate a universally unique identifier (UUID) automatically by typing "uuid" in the 'Default value' text box.Leave empty if you want to fill in the values later on.</li>
						<li>'Boolean': you can set all rows to be 'true' for example by typing "true" in the 'Default value' text box. Possible values are “true” and “false”.</li>
					</ul>
					Leave empty if you want to fill in the values later on.
				</li>
				<li>Click on 'Add column' button when you have finished the column configuration. The column is now shown in the table preview on the left side.
				<br/>
					Note that columns cannot be edited once you add them. However, you can click on 'Remove' button to delete the column, and then you can add it again with the desired changes.
				</li>
				<li>Click on 'Create file' when all desired columns have been added. The file is opened in a new tab where you can continue to edit it.</li>
			</ol>
			<h3>View and edit files</h3>
			<p>Imported files or newly created ones are opened in a dedicated tab of the TSV Editor. The tab has the same name as the file.</p>
			<p>The file content is displayed in a table format.</p>
			<p>Navigate the file by clicking on the previous/next navigation arrows located above and below the table. The total number of rows is displayed under the navigation arrows on top of the table.</p>
			<p>You can choose how many rows you want to see at a time by selecting a predefined number of rows in the 'Quantity' dropdown.</p>
			<h4>Table View</h4>
			<p>'R.F.E' (Remove From Export) is a default column that allows you to select which rows you want to remove when exporting the file. Click on the checkbox to select the rows that will be excluded from the export.</p>
			<p>Order a column content in ascending or descending order by clicking on the dash sign next to the column name.</p>
			<p>You can search the column content either by doing a simple search or by using regular expressions (regexp).</p>
			<ul>
				<li>For simple searches, type the search value in the first text box on top of a column; the search is case sensitive.</li>
				<li>For regular expression searches, use the second text box on top of a column.</li>
			</ul>
			<p>Note that the two search types cannot be combined on the same column. However, you can use simple searches on one column and regular expressions on another column.</p>
			<p>The rows that have a match are displayed and the search results are highlighted in the text.</p>
			<p>There is also a match result in percentage for the found rows out of the total number of rows in the file. This is displayed under the navigation arrows above the table.</p>
			<p>To edit the content of a row, you have to click in a cell, then make the changes you want. To exit the cell, either click outside it or navigate to another cell using the keyboard up/down and left/right arrows.</p>
			<p>To add extra rows to the file, click on the 'Add row' button on the top left side of the page.</p>
			<p>A form will open where you can provide values for each column. If the columns have predefined default values, these will be populated in the form. However, you can overwrite these values if you choose to do so.</p>
			<p>Click the 'Add row' button in the form if you want to add the row to the file or click 'Cancel' if you changed your mind.</p>
			<p>The rows are added at the end of the file.</p>
			<p>Note that rows cannot be removed but you can always mark them as R.F.E to exclude them from the exported file.</p>
			<h4>Column Settings</h4>
			<p>You can add new columns or customize the existing ones if you click on the 'Column settings' button on the top right corner of the page.</p>
			<p>To add a new column, provide the name, type, and default value (if any) in the bottom side of the 'Column settings' form. Then click the 'Add column' button to save the new column to your file.</p>
			<p>New columns will be added at the end of the existing ones.</p>
			<p>The top part of the 'Column settings' form allows you to customize the content of the columns and how the columns are displayed.</p>
			<ul>
				<li>Use the sliders in the 'Width' field to decrease or increase the width of a specific column by dragging the slider button to the left or to the right</li>
				<li>Change the data type for a column by selecting another value in the 'Type' dropdown</li>
				<li>Set a default value in the 'Default value' field. See details on what values are accepted in  'Create a new file' - the default values section.</li>
				<li>Unselect the checkbox in the 'Show' field to hide a specific column from the file view.All changes are applied automatically.</li>
			</ul>
			<p>Note that for 'Type' and 'Default value' the new configuration is not applied to existing data. For example, if a number column has been changed to use the 'Boolean' type, you will still see numerical values in your file. </p>
			<p>However, when you edit the cell content for this column, specific validators for the new Boolean data type will be applied.</p>
			<h4>Export file</h4>
			<p>To export a file, click on the 'Export file' button in the top left side of the page. An 'Export file' form is opened where you can customize the final look of the exported file.</p>
			<p>'File name' takes the name of the tab but you can change it to any other name you want. The '.csv' file extension will be added automatically so you don't have to specify it here.</p>
			<p>You can export the file in csv format and you can choose the delimiter from the 'Delimiter' dropdown to be either comma, semicolon or tab.</p>
			<p>All the columns from the file are displayed in a table view. You can change a column name by typing a new value in the field 'Name to use in export'.</p>
			<p>To mark which columns to export, select the checkbox next to each column. Clicking the checkbox in the table header allows you to select/unselect all columns at once.</p>
			<p>The column order can be changed either by clicking the up/down arrows, to move them one level up/down, or by drag & drop to the desired position.</p>
			<p>The 'Export search results' option allows you to export only those rows in your file that match a specific search criteria.</p>
			<p>Note that if no search filter is applied and the option is selected, all the rows in the file are exported (except the ones marked as R.F.E).</p>
			<h4>Remove File</h4>
			<p>Click on 'Remove file' button to close a file that you no longer want to work on. Keep in mind that once you confirm the file removal, this action cannot be undone.</p>
			<p>If you need the file for later use, it is recommended that you export it before removing in it from view.</p>
			<h3>Data storage</h3>
			<p>When you import files, the data is stored on your local machine.</p>
			<p>The files are available in your browser as long as you don't clear the cache and cookies or until you decide to remove them.</p>
			<p>In order to take advantage of the cloud storage capability you first need to register and log in. This will enable the options to store and retrieve files to and from the cloud.</p>
			<p>To save a file to the cloud, open an imported file and click the 'Store' button in the top left side of the page. This action will copy your local file to the cloud.</p>
			<p>If you make changes to the file and then store it, any existing copies in the cloud will be overwritten by the new version.</p>
			<p>To retrieve a file from the cloud, click on the 'Retrieve' button. This action will download the latest version stored on the cloud.</p>
			<p>If you have made changes to your local file in the meantime, these changes will be overwritten by the version downloaded from the cloud.</p>
			<p>You can also choose to enable the 'Auto Sync' option by clicking on the toggle between the 'Store' and 'Retrieve' buttons. In this mode, all the changes you make are automatically saved to the cloud.</p>
			<p>Note that you need to enable the 'Auto Sync' for a file each time you work on it in a new login session.</p>
			<p>When working with the cloud storage capability, all your uploaded files become available even if you log in from a different machine.</p>
			<p>To retrieve your files from the cloud, go to the 'Add file' tab and click on the 'Import from account' button. This will download all the files and it will open them in separate tabs.</p>
			<p>Keep in mind that when you remove a file, the file is removed from the cloud as well, and the action cannot be undone.</p>
		</div>
	);
}

export default UserGuide;