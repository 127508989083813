export let dbs = {};

export function getRowsSearch(fileName, page, quantity, searches, regexpSearches, sortedColumn) {
    const transaction = dbs[fileName].transaction(["rows"], "readonly");
    const objectStore = transaction.objectStore("rows");

    let request;

    if (!sortedColumn) {
        request = objectStore.openCursor();
    }
    else {
        const direction = sortedColumn.sortOrder === "ASC" ? "next" : "prev";
        request = objectStore.index(sortedColumn.columnName).openCursor(null, direction);
    }

    let rows = [];
    let count = 0;

    quantity = parseInt(quantity);

    return new Promise((resolve, reject) => {
        request.onsuccess = (evt )=> {
            const cursor = evt.target.result;
            if (cursor) {

                const currentRow = cursor.value;
                let found = true;
                for (let i = 0; i < searches.length; i++) {
                    if (!currentRow.row[searches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')].includes(searches[i].search)) {
                        found = false;
                        break;
                    }
                }
                for (let i = 0; i < regexpSearches.length; i++) {
                    try{
                        if (!new RegExp(regexpSearches[i].search, 'gu').test(currentRow.row[regexpSearches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')])) {
                            found = false;
                            break;
                        }
                    }
                    catch (e) {
                        found = false;
                    }
                }

                if (found) {
                    count++;
                    if (count > page * quantity) {
                        rows.push(currentRow);
                    }
                }
                if (rows.length === quantity) {
                    resolve(rows);
                }
                else {
                    cursor.continue();
                }
            }

            else {
                resolve(rows);
            }
        };
        request.onerror = (error) =>{
            reject(error);
        };
    });
}

export function getRows(fileName, page, quantity, sortedColumn) {
    const transaction = dbs[fileName].transaction(["rows"], "readonly");
    const objectStore = transaction.objectStore("rows");

    quantity = parseInt(quantity);
    let advanced = false;

    if (!sortedColumn) {
        let rows = [];

        const request = objectStore.openCursor();

        return new Promise((resolve, reject) => {
            request.onsuccess = (evt) => {
                const cursor = evt.target.result;

                if (cursor) {
                    if (!advanced && page !== 0) {
                        cursor.advance(page * quantity);
                        advanced = true;
                    }
                    else {
                        rows.push(cursor.value);
                        if (rows.length < quantity) {
                            cursor.continue();
                        }
                        else {
                            resolve(rows);
                        }
                    }
                }
                else {
                    resolve(rows);
                }
            };
            request.onerror = (evt) => {
                reject(evt);
            };
        });
    }
    else {
        const direction = sortedColumn.sortOrder === "ASC" ? "next" : "prev";
        const request = objectStore.index(sortedColumn.columnName).openCursor(null, direction);
        let rows = [];


        return new Promise((resolve, reject) => {
            request.onsuccess = (evt )=> {
                const cursor = evt.target.result;
                if (cursor) {
                    if (!advanced && page !== 0) {
                        cursor.advance(page * quantity);
                        advanced = true;
                    }
                    else {
                        rows.push(cursor.value);
                        if (rows.length < quantity) {
                            cursor.continue();
                        }
                        else {
                            resolve(rows);
                        }
                    }
                }
                else {
                    resolve(rows);
                }
            };
            request.onerror = (error) =>{
                console.error(error);
                reject(error);
            };
        });
    }
}

export function getMaxPage(fileName, quantity) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");
    const objectStore = transaction.objectStore("rows");
    const request = objectStore.count();

    return new Promise((resolve, reject) => {
        request.onsuccess = (evt )=> {
            resolve(Math.floor((evt.target.result - 1) / quantity));
        };
        request.onerror = (error) =>{
            reject(error);
        };
    });
}

export function getMaxPageSearch(fileName, quantity, searches, regexpSearches) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");
    const objectStore = transaction.objectStore("rows");
    const request = objectStore.openCursor();

    let count = 0;

    return new Promise((resolve, reject) => {
        request.onsuccess = (evt )=> {
            const cursor = evt.target.result;
            if (cursor) {
                const currentRow = cursor.value;
                let found = true;

                for (let i = 0; i < searches.length; i++) {
                    if (!currentRow.row[searches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')].includes(searches[i].search)) {
                        found = false;
                        break;
                    }
                }
                for (let i = 0; i < regexpSearches.length; i++) {
                    try{
                        if (!new RegExp(regexpSearches[i].search, 'gu').test(currentRow.row[regexpSearches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')])) {
                            found = false;
                            break;
                        }
                    }
                    catch (e) {
                        found = false;
                    }
                }

                if (found) {
                    count ++;
                }
                cursor.continue();
            }
            else {
                resolve(Math.floor((count - 1) / quantity));
            }
        };
        request.onerror = (error) =>{
            reject(error);
        };
    });
}

export function getCountSearch(fileName, searches, regexpSearches) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");
    const objectStore = transaction.objectStore("rows");
    const request = objectStore.openCursor();

    let count = 0;

    return new Promise((resolve, reject) => {
        request.onsuccess = (evt )=> {
            const cursor = evt.target.result;
            if (cursor) {
                const currentRow = cursor.value;
                let found = true;

                for (let i = 0; i < searches.length; i++) {
                    if (!currentRow.row[searches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')].includes(searches[i].search)) {
                        found = false;
                        break;
                    }
                }
                for (let i = 0; i < regexpSearches.length; i++) {
                    try{
                        if (!new RegExp(regexpSearches[i].search, 'gu').test(currentRow.row[regexpSearches[i].column.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')])) {
                            found = false;
                            break;
                        }
                    }
                    catch (e) {
                        found = false;
                    }
                }

                if (found) {
                    count ++;
                }
                cursor.continue();
            }
            else {
                resolve(count);
            }
        };
        request.onerror = (error) =>{
            reject(error);
        };
    });
}

export function addRows(rows, fileName) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");

    const objectStore = transaction.objectStore("rows");
    for (let i = 0; i < rows.length; i++) {
        objectStore.add(rows[i]);
    }

    return new Promise((resolve, reject) => {
        transaction.oncomplete = (evt) => {
            resolve();
        }
        transaction.onerror = (error) => {
            reject(error);
        }
    });
}

export function clearRows(fileName) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");

    const objectStore = transaction.objectStore("rows");
    objectStore.clear();

    return new Promise((resolve, reject) => {
        transaction.oncomplete = (evt) => {
            resolve();
        }
        transaction.onerror = (error) => {
            reject(error);
        }
    });
}

export function updateRow(row, fileName) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");

    const objectStore = transaction.objectStore("rows");
    objectStore.put(row);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = (evt) => {
            resolve();
        }
        transaction.onerror = (error) => {
            reject(error);
        }
    });
}

export function getCount(fileName) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");
    const objectStore = transaction.objectStore("rows");
    const request = objectStore.count();

    return new Promise((resolve, reject) => {
        request.onsuccess = (evt )=> {
            resolve(evt.target.result);
        };
        request.onerror = (error) =>{
            reject(error);
        };
    });
}

export function removeDb(fileName) {
    dbs[fileName].close();

    const request = indexedDB.deleteDatabase(fileName);

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            resolve();
        };

        request.onerror = (err) => {
            reject(err);
        };

        request.onblocked = (err) => {
            console.log("blocked");
            reject(err);
        }
    });
}

export function addRow(fileName, row) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");
    const objectStore = transaction.objectStore("rows");

    objectStore.add(row);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = (evt) => {
            resolve();
        }
        transaction.onerror = (error) => {
            reject(error);
        }
    });
}

export function deleteRow(fileName, id) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");
    const objectStore = transaction.objectStore("rows");

    objectStore.delete(id);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = (evt) => {
            resolve();
        }
        transaction.onerror = (error) => {
            reject(error);
        }
    });
}

export function getColumns(fileName) {
    const transaction = dbs[fileName].transaction("rows", "readwrite");
    const objectStore = transaction.objectStore("rows");
    const request = objectStore.openCursor();
    const indexNames = [...objectStore.indexNames];

    return new Promise((resolve, reject) => {
        request.onsuccess = (evt) => {
            const cursor = evt.target.result;
            if (cursor) {
                const keyNames = Object.keys(cursor.value.row);
                let columnNames = [];

                for (let i = 0; i < keyNames.length; i++) {
                    for (let j = 0; j < indexNames.length; j++) {
                        if (indexNames[j].replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW') === keyNames[i]) {
                            columnNames.push(indexNames[j]);
                        }
                    }
                }

                resolve(columnNames);
            }
            else {
                reject("No rows found");
            }
        }
        request.onerror = (error) => {
            reject(error);
        }
    });
}

export function initDb(fileName, columns = [], version = 1) {
    const request = indexedDB.open(fileName, version);

    return new Promise((resolve, reject) => {
        request.onerror = function(event) {
            console.log(event);
            console.log("Why didn't you allow my web app to use IndexedDB?!");
            reject();
        };
        request.onsuccess = function(event) {
            let db = event.target.result;
            dbs[fileName] = db;
            resolve();
        };
        request.onupgradeneeded = async function(event) {
            let db = event.target.result;
            dbs[fileName] = db;
            const transaction = event.target.transaction;
            let objectStore;

            const objectStoreNames = db.objectStoreNames;
            let objectStoreExists = false;

            for (let i = 0; i < objectStoreNames.length; i++) {
                if (objectStoreNames[i] === "rows") {
                    objectStore = transaction.objectStore('rows');
                    objectStoreExists = true;
                    break;
                }
            }

            if (!objectStoreExists) {
                objectStore = db.createObjectStore("rows", { keyPath: "id" });
            }

            try {
                const indexNames = [...objectStore.indexNames];


                for (let i = 0; i < columns.length; i++) {
                    if (!indexNames.includes(columns[i])) {
                        console.log(columns[i]);
                        objectStore.createIndex(columns[i], 'row.' + columns[i].replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW'));
                    }
                }

                for (let i = 0; i < indexNames.length; i++) {
                    if (!columns.includes(indexNames[i])) {
                        objectStore.deleteIndex(indexNames[i]);
                    }
                }

                dbs[fileName] = db;
                transaction.oncomplete = () => {
                    resolve();
                }
            }
            catch (e) {
                console.error(e);
                reject();
            }
        };
    });
}

export function closeDb(fileName) {
    dbs[fileName].close();
}

export function getVersion(fileName) {
    return dbs[fileName].version;
}
