import React, {useState} from "react";

function Number({initialValue, updateCell, onBlur, onKeyDown}) {
    const [value, setValue] = useState(initialValue);

    async function onChange(evt) {
        evt.persist();
        setValue(evt.target.value);
        await updateCell(evt.target.value);
    }

    return <input type={"number"} autoFocus={true} onBlur={onBlur} onChange={onChange} onKeyDown={onKeyDown} style={{width: '100%'}} value={value} />;
}

export default Number;
