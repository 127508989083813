import React, {useState} from "react";

function Boolean({initialValue, updateCell, onBlur, onKeyDown}) {
    const [value, setValue] = useState(initialValue === "true");

    async function onChange(evt) {
        evt.persist();
        setValue(evt.target.checked);
        await updateCell(evt.target.checked ? "true": "false");
    }

    return <input type={"checkbox"} autoFocus={true} onKeyDown={onKeyDown} onBlur={onBlur}  onChange={onChange} style={{width: '100%'}} checked={value} />;
}

export default Boolean;
