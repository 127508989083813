import React, {useEffect, useState} from 'react';
import FileTab from "./components/FileTab";
import AddFile from "./components/AddFile";
import Login from "./components/Login";
import Register from "./components/Register";
import Logout from "./components/Logout";
import Error from "./components/generic/Error";
import Success from "./components/generic/Success";
import UserGuide from "./components/UserGuide";
import { v4 as uuid } from 'uuid';
import {initDb, removeDb} from "./db";
import {getUser} from "./models/users";

import {
    Switch,
    Route,
    Redirect,
    NavLink, useHistory
} from "react-router-dom";
import {removeFile as removeFileServer} from "./models/files";
import RemoveFile from "./components/RemoveFile";

function App() {
    const [files, setFiles] = useState([]);
    const [dbsInitialized, setDbsInitialized] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [user, setUser] = useState({});
    const [errors, setErrors] = useState([]);
    const [successes, setSuccesses] = useState([]);
    const [autoSync, setAutoSync] = useState(false);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const filesFromLocalStorage = localStorage.getItem("tsvFiles");
                if (filesFromLocalStorage) {
                    let filesFromLocalStorageJson = JSON.parse(filesFromLocalStorage);
                    for (let i = 0; i < filesFromLocalStorageJson.length; i++) {
                        await initDb(filesFromLocalStorageJson[i].fileName, null, filesFromLocalStorageJson[i].version);
                        if (!filesFromLocalStorageJson[i].uuid) {
                            filesFromLocalStorageJson[i].uuid = uuid();
                        }
                    }
                    setFiles(filesFromLocalStorageJson);
                }
                setDbsInitialized(true);
                if (token) {
                    const response = await getUser(token, changeToken, changeUser);
                    if (response.errors.length <= 0) {
                        let userFromLocalStorage = localStorage.getItem('user');
                        if (userFromLocalStorage) {
                            try {
                                userFromLocalStorage = JSON.parse(userFromLocalStorage);
                                setUser(userFromLocalStorage);
                            }
                            catch (e) {}
                        }
                    }
                    else {
                        changeToken(null);
                        changeUser({});
                    }
                }
            }
            catch (e) {
                console.error(e);
            }
        })();
    }, []);

    useEffect(() => {
        if (dbsInitialized) {
            localStorage.setItem('tsvFiles', JSON.stringify(files));
        }
    }, [files]);

    function addFile(fileName) {
        setFiles(prevState => {
            return [...prevState, {
                fileName: fileName,
                version: 1,
                uuid: uuid()
            }];
        });
        if (!dbsInitialized) {
            setDbsInitialized(true);
        }
    }

    async function removeFile(fileName) {
        try {
            await removeDb(fileName);
            if (token) {
                await removeFileServer(fileName, token, changeToken, changeUser);
            }

        }
        catch (e) {
            console.error(e);
        }

        localStorage.removeItem(fileName + "searches");
        localStorage.removeItem(fileName + "columns");
        setFiles(prevState => {
            return prevState.filter(({fileName: fileNameInFiles}) => {
                return fileNameInFiles !== fileName;
            })
        });
        history.push('/addFile');
    }

    function changeToken(token) {
        if (token === null) {
            localStorage.removeItem("token");
            setAutoSync(false);
        }
        else{
            localStorage.setItem('token', token);
        }
        setToken(token);
    }

    function changeUser(user) {
        setUser(user);
        try {
            user = JSON.stringify(user);
        }
        catch (e) {
            user = JSON.stringify({});
        }
        localStorage.setItem('user', user);
    }

    function changeVersion(fileName, version) {
        const newFiles = [...files];

        for (let i = 0; i < newFiles.length ;i++) {
            if (newFiles[i].fileName === fileName) {
                newFiles[i].version = version;
            }
        }

        setFiles(newFiles);
    }

    return (
        <div className={"container-fluid bg-light"} style={{
            height: '100vh'
        }}>
            <nav className={"navbar navbar-expand-lg navbar-light"} style={{
                backgroundColor: '#e3f2fd',

            }}>
                <a className="navbar-brand" href="#">TSV Editor</a>
                <div className={"navbar-nav mr-auto"}>
                    <NavLink  to={'/login'} className={"nav-link"  + (token ? " disabled" : "")} activeClassName={"active"}>Login</NavLink>
                    <NavLink  to={'/register'} className={"nav-link" +  (token ? " disabled" : "")} activeClassName={"active"}>Register</NavLink>
                    <NavLink  to={'/userGuide'} className={"nav-link"} activeClassName={"active"}>User Guide</NavLink>

                </div>
                <Logout user={user} token={token} changeToken={changeToken} changeUser={changeUser}/>
            </nav>
            <ul className={"nav nav-tabs mt-2"}>
                <li className="nav-item">
                    <NavLink  to={'/addFile'} className={"nav-link"} activeClassName={"active"}>Add file</NavLink>
                </li>
                {files.map(({fileName: file, uuid}, index) => {
                    return (
                    <li className={"nav-item"} key={index}>
                        <NavLink to={'/files/' + uuid} className={"nav-link"} activeClassName={"active"}>{file}
                            <button onClick={(evt) => {
                                evt.preventDefault();
                            }} type={"button"} data-toggle={"modal"} data-target={"#removeModal" + uuid} className={"ml-2 close"} aria-label={"close"}><span aria-hidden="true">&times;</span></button>
                        </NavLink>
                        <RemoveFile removeFile={removeFile} fileName={file} fileId={uuid} token={token} changeUser={changeUser} changeToken={changeToken}/>
                    </li>
                    );
                })}
            </ul>

            <div className={"mt-2"}>
                {errors.map((error, index) => <Error message={error} key={index} />)}
                {successes.map((success, index) => <Success message={success} key={index} />)}
            </div>

            <Switch>
                <Route exact={true} path={'/register'}>
                    <Register changeUser={changeUser} changeToken={changeToken} token={token} setErrors={setErrors} setSuccesses={setSuccesses}/>
                </Route>
                <Route exact={true} path={'/login'}>
                    <Login changeUser={changeUser} changeToken={changeToken} token={token} setErrors={setErrors} setSuccesses={setSuccesses}/>
                </Route>
                <Route exact={true} path={'/'}>
                    <Redirect to={'/addFile'} />
                </Route>
                <Route exact={true} path={'/addFile'}>
                    <AddFile addFile={addFile} files={files} token={token} changeToken={changeToken} changeUser={changeUser} setErrors={setErrors} setSuccesses={setSuccesses}/>
                </Route>
                <Route exact={true} path={'/files/:fileUuid'}>
                    {dbsInitialized && <FileTab files={files} changeVersion={changeVersion} autoSync={autoSync} setAutoSync={setAutoSync} removeFile={removeFile} token={token} changeToken={changeToken} changeUser={changeUser} setSuccesses={setSuccesses} setErrors={setErrors}/>}
                </Route>
                <Route exact={true} path={'/userGuide'}><UserGuide /></Route>
            </Switch>
        </div>
    )
}

export default App;
