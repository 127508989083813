import React from "react";
import Input from "./inputTypes/Input";

function Cell({rowKey, columnKey, selectedIndex, setSelectedIndex, value, textToShow, columns, rows, setRows, fileName, token, changeToken, changeUser, autoSync}) {
    const realColumnKey = columns.filter(column => column.show)[columnKey].realIndex;



    function onCellClick(evt) {
        setSelectedIndex([rowKey, realColumnKey]);
    }

    let cellStyle = {
        position: "relative"
    };

    if (selectedIndex && selectedIndex[0] === rowKey && selectedIndex[1] === realColumnKey) {
        cellStyle.border = "1px solid grey";
        cellStyle.fontWeight = "bold";
    }
    else {
        cellStyle.border = null;
        cellStyle.fontWeight = "normal";
    }

    return (
        <td onClick={(evt) => {
            onCellClick(evt, rowKey, columnKey);
        }} style={cellStyle} >
            {(selectedIndex && selectedIndex[0] === rowKey && selectedIndex[1] === realColumnKey) ? <Input columnKey={realColumnKey} autoSync={autoSync} columns={columns} setSelectedIndex={setSelectedIndex} initialValue={value} selectedIndex={selectedIndex} rows={rows} setRows={setRows} fileName={fileName} token={token} changeToken={changeToken} changeUser={changeUser}/> : <p dir={"auto"} className={"d-inline"} dangerouslySetInnerHTML={{__html: columns[realColumnKey].type === "html" ? value: textToShow}} />}
        </td>
    );
}

export default Cell;
