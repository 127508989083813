import React, {useEffect, useState} from "react";
import Error from "./generic/Error";
import { v4 as uuid } from 'uuid';
import {addRows, clearRows, initDb, removeDb} from "../db";
import Success from "./generic/Success";
import Spinner from "./generic/Spinner";

function CreateFile({files, addFile}) {
	const [fileName, setFileName] = useState('');
	const [error, setError] = useState('');
	const [columns, setColumns] = useState([]);
	const [columnName, setColumnName] = useState('');
	const [amountOfRows, setAmountOfRows] = useState(1);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState('');
	const [defaultValue, setDefaultValue] = useState("");
	const [defaultValuePlaceHolder, setDefaultValuePlaceHolder] = useState("");
	const [type, setType] = useState('text');


	useEffect(() => {
		if (type === "text") {
			setDefaultValuePlaceHolder("Any string | uuid");
		}
		else if (type === "number") {
			setDefaultValuePlaceHolder("1234.01 | autoincrement");
		}
		else if (type === "boolean") {
			setDefaultValuePlaceHolder("true | false");
		}
		else if (type === "boolean") {
			setDefaultValuePlaceHolder("Any legal HTML");
		}
	}, [type]);

	async function createFile(evt) {
		evt.preventDefault();

		setError('');
		setSuccess('');

		if (columns.length <= 0) {
			setError('Please make sure you have at least 1 column.');
		}
		else {
			setLoading(true);

			if (files.map(file => file.fileName).includes(fileName.trim())) {
				setError("You already a file with name: '" + fileName + "'.");
				setLoading(false);
			}
			else {
				try {
					let rowsToSet = new Array(parseInt(amountOfRows));

					for (let i = 0; i < rowsToSet.length; i++) {
						let row = {};
						for (let j = 0; j < columns.length; j++) {
							if (columns[j].defaultValue === "autoincrement") {
								row[columns[j].name.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = (i + 1).toString();
							}
							else if (columns[j].defaultValue === "uuid") {
								row[columns[j].name.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = uuid();
							}
							else if (columns[j].defaultValue) {
								row[columns[j].name.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = columns[j].defaultValue.toString();
							}
							else {
								row[columns[j].name.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = '';
							}
						}
						rowsToSet[i] = {
							id: i,
							row,
							removeFromExport: false
						};
					}


					await initDb(fileName.trim(), columns.map(value => value.name));
					await clearRows(fileName.trim());
					await addRows(rowsToSet, fileName.trim());

					const width = Math.floor(100 / columns.length);
					localStorage.setItem(fileName.trim() + "columns", JSON.stringify(columns.map((value, index) => {
						return {...value, width, realIndex: index};
					})));

					addFile(fileName.trim());
					setSuccess("Successfully added " + fileName.trim() + ".");
				}
				catch (e) {
					console.error(e);
					setError('Failed to create this file.');
					try {
						await removeDb(fileName.trim());
					}
					catch (e) {
						console.error(e);
					}
				}
				finally {
					setLoading(false);
				}
			}
		}
	}

	function addColumn() {
		setError('');
		if (columns.map(value => value.name).includes(columnName.trim())) {
			setError("You already have a column name '" + columnName.trim() + "'.");
		}
		else {
			setColumns((prevState) => {
				return [...prevState, {
					id: uuid(),
					name: columnName.trim(),
					type,
					show: true,
					width: 0,
					defaultValue
				}];
			});
		}
		setDefaultValue('');
	}

	function removeColumn(columnId) {
		setColumns((prevState) => {
			return [...prevState].filter(value => value.id !== columnId);
		});
	}

	if (loading) {
		return  <Spinner />
	}
	else {
		return (
			<form onSubmit={createFile}>
				{error && <Error message={error} />}
				{success && <Success message={success} />}

				<div className="form-row">
					<div className="form-group col">
						<label htmlFor="fileName">File name:</label>
						<input type="text" id={"fileName"} className={"form-control"} placeholder={"File name"} required={true} value={fileName} onChange={(evt) => {
							setFileName(evt.target.value);
						}}/>
					</div>
					<div className="form-group col-3">
						<label htmlFor="amountOfRows">Amount of rows:</label>
						<input type="number" min={1} id={"amountOfRows"} className={"form-control"} placeholder={"Amount of rows"} required={true} value={amountOfRows} onChange={(evt) => {
							setAmountOfRows(evt.target.value);
						}}/>
					</div>
				</div>


				<div className="row">
					<div className="col">
						<table className={"table table-sm table-light table-striped"}>
							<caption style={{captionSide: 'top'}}>Columns</caption>
							<thead className={"thead-dark"}>
							<tr>
								<th>Name</th>
								<th>Type</th>
								<th>Default Value</th>
								<th>Actions</th>
							</tr>
							</thead>
							<tbody>
							{columns.map((value) => {
								return (<tr key={value.id}>
									<td>{value.name}</td>
									<td>{value.type}</td>
									<td>{value.defaultValue}</td>
									<td><button className={"btn btn-danger btn-sm"} onClick={(evt) => {
										removeColumn(value.id);
									}}>Remove</button></td>
								</tr>);
							})}
							</tbody>
						</table>
					</div>
					<div className="col-5">
						<div className="form-group">
							<label htmlFor="columnName">Name:</label>
							<input type="text" id={"columnName"} className={"form-control"} placeholder={"Column name"} value={columnName} onChange={(evt) => {
								setColumnName(evt.target.value);
							}} />
						</div>
						<div className="form-group">
							<label htmlFor="type">Type:</label>
							<select id={"type"} className={"form-control"} required={true} value={type} onChange={(evt) => {
								setType(evt.target.value);
							}}>
								<option value="number">Number</option>
								<option value="text">Text</option>
								<option value="boolean">Boolean</option>
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="defaultValue">Default value:</label>
							<input type={"text"} id={"defaultValue"} placeholder={defaultValuePlaceHolder} className={"form-control"} value={defaultValue} onChange={(evt) => {
								const defaultValue = evt.target.value;

								if (type === "number" && (!isNaN(defaultValue) || defaultValue === "" || "autoincrement".startsWith(defaultValue))) {
									setDefaultValue(defaultValue);
								}
								else if (type === "boolean" && (defaultValue === "true" || defaultValue === "false" || defaultValue === "" || "true".startsWith(defaultValue) || "false".startsWith(defaultValue))) {
									setDefaultValue(defaultValue);
								}
								else if (type === "text"){
									setDefaultValue(defaultValue);
								}
							}} />
						</div>
						<button className={"btn btn-primary"} onClick={addColumn} type={"button"}>Add column</button>
					</div>
				</div>


				<button className={"btn btn-success"} type={"submit"}>Create file</button>
			</form>
		);
	}
}

export default CreateFile;