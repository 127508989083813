import React, {useState, useEffect} from "react";
import autosize from "autosize/dist/autosize";

function Text({initialValue, updateCell, onBlur, onKeyDown}) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        const textArea = document.querySelector('textarea');

        autosize(textArea);
        textArea.focus();
        textArea.value = '';
        textArea.value = value;
        return () => {

        };
    }, [])

    async function onChange(evt) {
        setValue(evt.target.value);
        evt.persist();
        await updateCell(evt.target.value);
    }

    return <textarea onKeyDown={onKeyDown} onBlur={onBlur} dir={"auto"} onChange={onChange} style={{width: '100%'}} value={value} />;
}

export default Text;
