import React from "react";

function Logout({user, token, changeUser, changeToken}) {
    function onSubmit(evt) {
        evt.preventDefault();
        changeToken(null);
        changeUser({});
    }

    return (
        <form onSubmit={onSubmit} className={"form-inline"}>
            <span className={"mr-2"}>{user.firstName} {user.lastName}</span>
            <button type={"submit"} disabled={!token} className={"btn btn-danger"}>Logout</button>
        </form>
    );
}

export default Logout;
