import React from "react";

function Dash() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-dash" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"/>
        </svg>
    );
}

export default Dash;
