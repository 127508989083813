import React, {Fragment, useEffect, useState} from "react";
import {getCount, addRow} from "../db";
import {addRows} from "../models/files";
import AddRowColumn from "./AddRowColumn";
import {v4 as uuid} from "uuid";

function AddRow({fileName, columns, changeRows, token, autoSync, setCount: setCountParent, changeToken, changeUser}) {
    const [inputFields, setInputFields] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setInputFields(new Array(columns.length).fill(''));
        (async () => {
            const count = await getCount(fileName);
            setCount(count);
        })();
    }, [columns.length, fileName]);

    useEffect(() => {
        window.$('#addRowModal').on('hidden.bs.modal', reset);

        return () => {
            window.$('#addRowModal').off('hidden.bs.modal');
        }
    }, [count]);

    async function addRowListener() {
        const count = await getCount(fileName);

        let row = {
            id: count,
            removeFromExport: false
        };

        let rowToAppend = {

        };

        for (let i = 0; i < inputFields.length; i++) {
            rowToAppend[columns[i].name.replace(/[\s\r\n]/g, 'P').replace(/\W/g, 'nW')] = inputFields[i];
        }

        row.row = rowToAppend;


        await addRow(fileName, row);
        setCountParent(count + 1);
        setCount(count + 1);

        await changeRows();

        if (autoSync && token) {
            await addRows(fileName, [row], token, changeToken, changeUser);
        }
    }

    function reset() {
        console.log("Reset");

        let inputFields = [];

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            let inputField = '';

            if (column.defaultValue === "autoincrement") {
                inputField = (count + 1).toString();
            }
            else if (column.defaultValue === "uuid") {
                inputField = uuid();
            }
            else if (column.defaultValue) {
                inputField = column.defaultValue.toString();
            }

            inputFields.push(inputField);
        }


        setInputFields(inputFields);
    }

    return (
            <Fragment>
                <button type={"button"} className={"btn btn-primary ml-1"} data-target={"#addRowModal"} data-toggle={"modal"}>Add row</button>

                <div className="modal fade" id={"addRowModal"} tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add row</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {columns.map((value, index) => {
                                    return <AddRowColumn column={value} key={index} index={index} count={count} inputFields={inputFields} setInputFields={setInputFields}/>
                                })}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary" data-dismiss={"modal"} onClick={addRowListener}>Add row</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );

}

export default AddRow;
