import React, {useEffect, useState} from "react";
import CaretDown from "./generic/CaretDown";
import CaretUp from "./generic/CaretUp";
import Dash from "./generic/Dash";

function Header({column, sortedColumn, setSortedColumn}) {
    const [sortOrder, setSortOrder] = useState(null);

    useEffect(() => {
        if (sortOrder) {
            setSortedColumn({
                columnIndex: column.realIndex,
                sortOrder: sortOrder,
                columnName: column.name
            });
        }
        else {
            setSortedColumn(null);
        }
    }, [sortOrder]);

    useEffect(() => {
        if (sortedColumn !== null && sortedColumn.columnIndex !== column.realIndex) {
            setSortOrder(null);
        }
    }, [sortedColumn]);

    function onHeaderClick(evt) {
        if (!sortOrder) {
            setSortOrder("ASC");
        }
        else if (sortOrder === "ASC") {
            setSortOrder("DESC");
        }
        else if (sortOrder === "DESC") {
            setSortOrder(null);
        }
    }

    return (
        <th style={{width: column.width + "%"}}>{column.name}
            <i onClick={onHeaderClick} style={{
                cursor: "pointer"
            }}>{sortOrder ? (sortOrder === "ASC" ?  <CaretUp /> : <CaretDown />): (<Dash />)}</i>
        </th>
    );
}

export default Header;
