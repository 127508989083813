import React, {useEffect} from "react";
import { v4 as uuid } from 'uuid';

function AddRow({column, count, index, setInputFields, inputFields}) {
	useEffect(() => {
		if (column.defaultValue === "autoincrement") {
			setInputFields((prevState) => {
				const newState = [...prevState];
				newState[index] = (count + 1).toString();
				return newState;
			});
		}
		else if (column.defaultValue === "uuid") {
			setInputFields((prevState) => {
				const newState = [...prevState];
				newState[index] = uuid();
				return newState;
			});
		}
		else if (column.defaultValue) {
			setInputFields((prevState) => {
				const newState = [...prevState];
				newState[index] = column.defaultValue.toString();
				return newState;
			});
		}
	}, [column.defaultValue, count]);


	switch (column.type) {
		case "number":
			return (
				<div className={"form-group"}>
					<label htmlFor={column.name}>{column.name}:</label>
					<input type="number" value={inputFields[index] || 0} id={column.name} className={"form-control"}
						   onChange={(evt) => {
							   evt.persist();

							   const value = evt.target.value;

							   setInputFields((prevState) => {
								   const newState = [...prevState];
								   newState[index] = value;
								   return newState;
							   });
						   }}/>
				</div>
			);
		case "boolean":
			return (
				<div className={"form-group"}>
					<label htmlFor={column.name}>{column.name}:</label>
					<input type={"checkbox"} checked={inputFields[index] === "true"} onChange={(evt) => {
						evt.persist();
						setInputFields((prevState) => {
							const newState = [...prevState];
							newState[index] = evt.target.checked ? "true": "false";
							return newState;
						});
					}}/>
				</div>
			);
		default:
			return (
				<div className={"form-group"}>
					<label htmlFor={column.name}>{column.name}:</label>
					<input type="text" value={inputFields[index] || ''} id={column.name} className={"form-control"}
				    	onChange={(evt) => {
							evt.persist();
						   	setInputFields((prevState) => {
							   const newState = [...prevState];
							   newState[index] = evt.target.value;
							   return newState;
						   	});
					   }}/>
				</div>
			);
	}
}

export default AddRow;
