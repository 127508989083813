import React, {useState} from "react";
import {getMaxPage, getRows} from "../../db";
import {addRows as addRowsServer, addColumns, clearRows as clearRowsServer, clearColumns} from "../../models/files";
import CloudUpload from "../generic/CloudUpload";

function Upload({count, token, fileName, changeToken, changeUser, processing, setProcessing, setErrors, columns}) {
    const [loading, setLoading] = useState(false);
    const [currentAmount, setCurrentAmount] = useState(0);

    async function upload() {
        setErrors([]);
        if (token) {
            setLoading(true);
            setProcessing(true);

            try {
                const maxPage = await getMaxPage(fileName, 50);
                const response = await clearRowsServer(fileName, token, changeToken, changeUser);

                if (response.errors.length > 0) {
                    setCurrentAmount(0);
                    setLoading(false);
                    setProcessing(false);
                    setErrors(prevErrors => {
                        return [...prevErrors, ...response.errors];
                    });
                    return;
                }

                const clearColumnsResponse = await clearColumns(fileName, token, changeToken, changeUser);

                if (clearColumnsResponse.errors.length > 0) {
                    setCurrentAmount(0);
                    setLoading(false);
                    setProcessing(false);
                    setErrors(prevErrors => {
                        return [...prevErrors, ...clearColumnsResponse.errors];
                    });
                    return;
                }

                const columnsResponse = await addColumns(fileName, columns, token, changeToken, changeUser);

                if (columnsResponse.errors.length > 0) {
                    setCurrentAmount(0);
                    setLoading(false);
                    setProcessing(false);
                    setErrors(prevErrors => {
                        return [...prevErrors, ...columnsResponse.errors];
                    });
                    return;
                }

                for (let i = 0; i <= maxPage; i++) {
                    const rows = await getRows(fileName, i, 50);
                    const response = await addRowsServer(fileName, rows, token, changeToken, changeUser);
                    if (response.errors.length > 0) {
                        setCurrentAmount(0);
                        setLoading(false);
                        setProcessing(false);
                        setErrors(prevErrors => {
                            return [...prevErrors, ...response.errors];
                        });
                        break;
                    }
                    setCurrentAmount(currentAmount => {
                        return currentAmount + rows.length;
                    });
                }
            }
            catch (e) {
                console.error(e);
            }
            setCurrentAmount(0);
            setLoading(false);
            setProcessing(false);
        }
    }

    let style = {
        cursor: "pointer",
        fontSize: "30px"
    };

    if (!token) {
        style.cursor = "";
    }


    return loading ? <p>{currentAmount}/{count} uploaded...</p>:
        (!processing ? <button disabled={!token} title={"Your local file will be copied as it is now to the cloud. Any existing copy in the cloud will be overwritten."} onClick={upload} className={"btn btn-warning"}><CloudUpload /> Store</button>: "");
}

export default Upload;
