const endPoint = process.env.REACT_APP_endPoint || 'https://api.tsvEditor.ldit.be';

export async function register (firstName, lastName, email, password) {
    const response = await fetch(endPoint + '/api/v1/users', {
        method: "POST",
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response.json();
}

export async function login (email, password) {
    const response = await fetch(endPoint + '/api/v1/auth', {
        method: "POST",
        body: JSON.stringify({
            email,
            password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response.json();
}

export async function getUser(token, setToken, setUser) {
    const response = await fetch(endPoint + '/api/v1/auth', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    if (response.status === 401) {
        setToken(null);
        setUser({});
    }
    return response.json();
}
